import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import BlogList from '../components/BlogList';

const BlogCategoryTemplate = ({
  data: {
    datoCmsBlogCategory: { seoMetaTags, title },
    latestPost,
    otherPosts,
  },
}) => (
  <Layout>
    <HelmetDatoCms seo={seoMetaTags} />
    <main>
      <PageHeader
        heading={title}
        breadcrumbs={[{ slug: 'blog', text: 'Blog' }]}
      />
      <BlogList
        items={[...latestPost.nodes, ...otherPosts.nodes]}
        isBlogListing={true}
      />
    </main>
  </Layout>
);

export const BlogCategoryTemplateQuery = graphql`
  query BlogCategoryTemplateQuery($id: String!) {
    datoCmsBlogCategory(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
    }
    latestPost: allDatoCmsBlog(
      filter: { category: { id: { eq: $id } } }
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 1
    ) {
      nodes {
        ...BlogCardFragment
        featuredImage {
          ...LatestBlogCardFeaturedImageFragment
        }
      }
    }
    otherPosts: allDatoCmsBlog(
      filter: { category: { id: { eq: $id } } }
      sort: { fields: meta___firstPublishedAt, order: DESC }
      skip: 1
    ) {
      nodes {
        ...BlogCardFragment
        featuredImage {
          ...OtherBlogCardFeaturedImageFragment
        }
      }
    }
  }
`;

export default BlogCategoryTemplate;
